<template>
    <!-- 过磅单核算 -->
    <div class="business-accounting weighingListBusiness" @click.prevent="clearHover">
        <div class="business-accounting-box" ref="waiBox">
            <div ref="topBox" class="query-criteria">
                <div>
                    <p>过磅单编号:</p>
                    <el-input :title="gbdbh" v-model="gbdbh" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>原材料分类:</p>
                    <el-select v-model="yclfl" @change="linkageSelect(1)" placeholder="请选择">
                        <el-option
                            v-for="item in getMaterialGroupList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p>原材料子类:</p>
                    <el-select v-model="yclzlbm" @change="linkageSelect(2)" placeholder="请选择">
                        <el-option
                            v-for="item in getMaterialChildList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p>原材料规格:</p>
                    <el-select v-model="yclggbm" @change="linkageSelect(3)" placeholder="请选择">
                        <el-option
                            v-for="item in getMaterialSpecList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.code"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p>供应商:</p>
                    <el-input v-model="gys" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>接收场站:</p>
                    <el-select
                        v-model="czmc"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in stationNameList"
                            :key="item.code"
                            :label="item.name"
                            :value="item.station_id"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p>运输单位:</p>
                    <el-input v-model="ysdw" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>车牌号:</p>
                    <el-input v-model="cph" placeholder="请输入内容"></el-input>
                </div>
                <div>
                    <p>状态:</p>
                    <el-select v-model="hdzt" placeholder="请选择">
                        <el-option
                            v-for="item in jsztList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <p>出场时间段:</p>
                    <el-date-picker
                        v-model="ccsjd"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </div>
                <div>
                    <p>过磅单打印时间段:</p>
                    <el-date-picker
                        v-model="gbddysjd"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    >
                    </el-date-picker>
                </div>
                <div>
                    <el-button @click="aaa" type="primary">
                        查询
                    </el-button>
                    <el-button type="primary" @click="exportExcel">
                        导出Excel
                    </el-button>
                </div>
            </div>
            <div ref="centerBtn">
                <el-button class="bulk-send" @click="bulkSend">
                    批量核算
                </el-button>
                <el-button class="bulk-send" @click="batchAdjustPrice">
                    批量调价
                </el-button>
            </div>
            <div ref="bottomTable" class="table-list">
                <el-table
                    :data="tableData"
                    height="100%"
                    stripe
                    border
                    ref="tableRefTwo"
                    highlight-current-row
                    :header-cell-style="headerStyle"
                    show-summary
                    v-loading="listLoading"
                    @selection-change="handleSelectionChange"
                    :summary-method="getSum"
                >
                    <el-table-column
                        label="选择"
                        type="selection"
                        width="60"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="tare_time"
                        label="出场时间"
                        show-overflow-tooltip
                        min-width="110"
                    >
                    </el-table-column>
                    <el-table-column
                        label="过磅单编号"
                        show-overflow-tooltip
                        width="200"
                    >
                        <template slot-scope="scope">
                            <p @click.stop="awakenAlert(scope.row)" class="yunshuNumber">
                                {{ scope.row.balance_number }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="供应商"
                        show-overflow-tooltip
                        min-width="200"
                    >
                        <template slot-scope="scope">
                            <el-popover
                                v-model="scope.row.show"
                                placement="right"
                                trigger="focus"
                                popper-class="business-accounting-popover"
                            >
                                <div class="click-look-details-check" :data="scope.row">
                                    <div>
                                        <p>过磅单编号</p>
                                        <el-tooltip :enterable="false" :content="scope.row.balance_number" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.balance_number }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>供应单编号</p>
                                        <el-tooltip :enterable="false" :content="scope.row.task_number" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.task_number }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>合同编号</p>
                                        <el-tooltip :enterable="false" :content="scope.row.contract_no" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.contract_no }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>供应商</p>
                                        <el-tooltip :enterable="false" :content="scope.row.supplier_name" placement="top">
                                            <p style="cursor:pointer;">
                                                {{ scope.row.supplier_name }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                    <div>
                                        <p>货源地</p>
                                        <p>{{ scope.row.source_name }}</p>
                                    </div>
                                    <div>
                                        <p>接收场站</p>
                                        <p>{{ scope.row.station_name }}</p>
                                    </div>
                                    <div>
                                        <p>原材料分类</p>
                                        <p>{{ scope.row.type_name }}</p>
                                    </div>
                                    <div>
                                        <p>原材料子类</p>
                                        <p>{{ scope.row.child_type_name }}</p>
                                    </div>
                                    <div>
                                        <p>原材料规格</p>
                                        <p>{{ scope.row.spec_name }}</p>
                                    </div>
                                    <div>
                                        <p>计量方式</p>
                                        <p>{{ scope.row.record_type_name }}</p>
                                    </div>
                                    <div>
                                        <p>出厂重量</p>
                                        <p>{{ scope.row.delivery_weight }}</p>
                                    </div>
                                    <div>
                                        <p>进场时间</p>
                                        <p>{{ scope.row.gross_time }}</p>
                                    </div>
                                    <div>
                                        <p>出厂时间</p>
                                        <p>{{ scope.row.delivery_time }}</p>
                                    </div>
                                    <div>
                                        <p>计量单位</p>
                                        <p>{{ scope.row.measure_unit }}</p>
                                    </div>
                                    <div>
                                        <p>运输单位</p>
                                        <p>{{ scope.row.transport_name }}</p>
                                    </div>
                                    <div>
                                        <p>运输车牌</p>
                                        <p>{{ scope.row.plate_number }}</p>
                                    </div>
                                    <div>
                                        <p>司机姓名</p>
                                        <p>{{ scope.row.driver_name }}</p>
                                    </div>
                                    <div>
                                        <p>司机电话</p>
                                        <p>{{ scope.row.driver_phone }}</p>
                                    </div>
                                    <div>
                                        <p>称毛时间</p>
                                        <p>{{ scope.row.gross_time }}</p>
                                    </div>
                                    <div>
                                        <p>毛重</p>
                                        <p>{{ scope.row.gross_weight }}</p>
                                    </div>
                                    <div>
                                        <p>反皮时间</p>
                                        <p>{{ scope.row.tare_time }}</p>
                                    </div>
                                    <div>
                                        <p>皮重</p>
                                        <p>{{ scope.row.tare_weight }}</p>
                                    </div>
                                    <div>
                                        <p>标准扣水</p>
                                        <p>{{ scope.row.deduction_water }}</p>
                                    </div>
                                    <div>
                                        <p>扣杂</p>
                                        <p>{{ scope.row.deduction_weight }}</p>
                                    </div>
                                    <div>
                                        <p>净重</p>
                                        <p>{{ scope.row.deduction_net_weight }}</p>
                                    </div>
                                    <div>
                                        <p>接收情况</p>
                                        <p>{{ scope.row.reception_result }}</p>
                                    </div>
                                    <div>
                                        <p>调整后扣水</p>
                                        <p>{{ scope.row.adjusted_deduction_water }}</p>
                                    </div>
                                    <div>
                                        <p>调整后扣杂</p>
                                        <p>{{ scope.row.adjusted_deduction_weight }}</p>
                                    </div>
                                    <div>
                                        <p>调整后净重</p>
                                        <p>{{ scope.row.adjusted_net_weight }}</p>
                                    </div>
                                    <div>
                                        <p>主材含税单价</p>
                                        <p>{{ scope.row.unit_price }}</p>
                                    </div>
                                    <div>
                                        <p>主材金额</p>
                                        <p>{{ scope.row.money }}</p>
                                    </div>
                                    <div>
                                        <p>运费含税单价</p>
                                        <p>{{ scope.row.transport_price }}</p>
                                    </div>
                                    <div>
                                        <p>运费</p>
                                        <p>{{ scope.row.transport_money }}</p>
                                    </div>
                                    <div>
                                        <p>其他费用</p>
                                        <p>{{ scope.row.other_fees }}</p>
                                    </div>
                                    <div>
                                        <p>小计金额</p>
                                        <p>{{ scope.row.subtotal }}</p>
                                    </div>
                                    <div>
                                        <p>核算人</p>
                                        <p>{{ scope.row.account_user_name }}</p>
                                    </div>
                                    <div>
                                        <p>核算人电话</p>
                                        <p>{{ scope.row.account_user_phone }}</p>
                                    </div>
                                    <div>
                                        <p>状态</p>
                                        <p>{{ scope.row.balance_status_name }}</p>
                                    </div>
                                </div>
                                <span style="cursor:pointer; color: blue; text-decoration: underline;" slot="reference">
                                    {{ scope.row.supplier_name }}
                                </span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="transport_name"
                        label="运输商"
                        show-overflow-tooltip
                        min-width="200"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="plate_number"
                        label="车牌号"
                        show-overflow-tooltip
                        min-width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="station_name"
                        label="接收场站"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="type_name"
                        label="原材料大类"
                        width="100"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="child_type_name"
                        label="原材料子类"
                        width="100"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="spec_name"
                        label="原材料规格"
                        show-overflow-tooltip
                        min-width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="deduction_net_weight"
                        label="净重"
                        min-width="150"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="delivery_weight"
                        label="出厂重量"
                        min-width="100"
                        show-overflow-tooltip
                    >
                    </el-table-column>

                    <el-table-column
                        prop="checked_net_weight"
                        label="核对后净重"
                        min-width="150"
                        show-overflow-tooltip
                    >
                    </el-table-column>

                    <el-table-column
                        prop="record_type_name"
                        label="计量方式"
                        min-width="130"
                        show-overflow-tooltip
                    >
                        <template slot-scope="scope">
                            {{ scope.row.record_type==1?'乙方重量为准':'甲方重量为准' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="gross_time"
                        label="进场时间"
                        min-width="180"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="delivery_time"
                        label="出厂时间"
                        min-width="180"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                        prop="unit_price"
                        label="含税单价"
                        width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="money"
                        label="主材金额"
                        min-width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="adjusted_money"
                        label="调整后主材金额"
                        min-width="150"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="transport_price"
                        label="运费单价"
                        width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="transport_money"
                        label="运费"
                        width="80"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="account_statement_status"
                        label="退回"
                        width="80"
                    >
                        <template slot-scope="scope">
                            <p @click.stop="handleBack(scope.row)" class="yunshuNumber">
                                {{ scope.row.account_statement_status !=1?'退回':'——' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="balance_status_name"
                        label="状态"
                        show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
            </div>
            <div class="fonter">
                <div class="multipleSelected">
                    已选 <span>{{ selectList.length }}</span> 条
                </div>
                已加载<span>{{ tableCurrentPage }}</span>条，
                共<span>{{ listLength }}</span>条
            </div>
        </div>
    </div>
</template>

<script>
// 过磅单核算
export default {
    data() {
        return {
            headerStyle: {}, // 表头样式
            // 列表数据
            tableData: [],
            // 列表当前加载页
            tableCurrentPage: 0,
            getMaterialGroupList: [],
            getMaterialChildList: [],
            getMaterialSpecList: [],
            // 接收场站下拉选项
            stationNameList: [],
            jsztList: [
                {
                    value: '',
                    label: '请选择',
                },
                {
                    value: '3',
                    label: '未核算',
                }, {
                    value: '4',
                    label: '已核算',
                }, {
                    value: '5',
                    label: '调整后核算',
                },
            ],
            hdzt: '',
            gbdbh: '',
            // gydbh: '',
            yclfl: '', // 原材料分类
            yclzlbm: '', // 原材料子类编码
            yclggbm: '', // 原材料规格编码
            gys: '',
            ysdw: '',
            cph: '',
            czmc: '', // 接收场站
            ccsjd: [],
            gbddysjd: [],
            // 合计数据
            totalData: {},
            tableDom: {},
            count: 1,
            listLoading: false,
            listLength: 0,
            allData: false,
            selectList: [],
        };
    },
    created() {
        this.tableRefNmame = 'tableRef_gbd';
        this.$store.commit('recordOpenList', {
            tableObj: this,
            tableRefNmame: this.tableRefNmame,
        });
    },
    mounted() {
        // 获取接收场站下拉数据
        this.getStationNameList();
        this.headerStyle = {
            textAlign: 'center',
            background: '#EDF0F5',
            color: '#022782',
            height: '.6rem',
        };
        this.tableDom = this.$refs.tableRefTwo.bodyWrapper;
        // 获取合计数据
        this.getTotalData();
        this.tableData = [];
        // 获取列表数据
        this.getHeSuanList();
        this.getMaterialGroup();

        const _that = this;
        $(this.tableDom).each(function () {$(this).data('slt', { st: this.scrollTop });}).scroll(function () {
            const st = this.scrollTop;
            const d = $(this).data('slt');
            if (st !== d.st) {
                // 滚动距离
                const scrollTop = Math.floor($(_that.tableDom).scrollTop());
                // 变量windowHeight是可视区的高度
                const windowHeight = $(_that.tableDom).height();
                // 变量scrollHeight是滚动条的总高度
                const scrollHeight = _that.tableDom.scrollHeight;
                if (scrollTop + windowHeight >= scrollHeight) {
                // 获取到的不是全部数据 当滚动到底部 继续获取新的数据
                    if (!_that.allData) {
                        _that.getMoreLog();
                    }
                }
            }
            $(this).data('slt', { st: st });
        });
    },
    methods: {
        // 联动选择
        linkageSelect(status) {
            if (status === 1) {
                this.yclzlbm = '';
                this.yclggbm = '';
                this.getMaterialChild();
            } else if (status === 2) {
                this.yclggbm = '';
                this.getMaterialSpec();
            }
        },
        // 原材料分类
        getMaterialGroup() {
            this.$axios
                .get('/interfaceApi/procurement/material/group/type/get')
                .then(res => {
                    this.getMaterialGroupList = res;
                    const obj = {
                        code: '',
                        name: '请选择',
                    };
                    this.getMaterialGroupList.unshift(obj);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 原材料子类
        getMaterialChild() {
            this.getMaterialChildList = [];
            this.getMaterialSpecList = [];
            this.$axios
                .get(`/interfaceApi/procurement/material/group/type/child/get/parent_code?parent_code=${this.yclfl}`)
                .then(res => {
                    this.getMaterialChildList = res;
                    const obj = {
                        code: '',
                        name: '请选择',
                    };
                    this.getMaterialChildList.unshift(obj);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 原材料规格
        getMaterialSpec() {
            this.getMaterialSpecList = [];
            this.$axios
                .get(`/interfaceApi/procurement/material/group/type/spec/get/parent_code?parent_code=${this.yclzlbm}`)
                .then(res => {
                    this.getMaterialSpecList = res;
                    const obj = {
                        code: '',
                        name: '请选择',
                    };
                    this.getMaterialSpecList.unshift(obj);
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 获取接收场站下拉数据
        getStationNameList() {
            this.stationNameList = [{
                station_id: '',
                name: '全部',
            }];
            const station_Names = this.$takeTokenParameters('StationNames').split(',');
            const station_IDs = this.$takeTokenParameters('StationIds').split(',');
            if ((station_Names.length || []) > 0) {
                station_Names.forEach((item, index) => {
                    this.stationNameList.push({
                        name: item,
                        station_id: station_IDs[index],
                    });
                });
            }
        },
        // 获取合计数据
        getTotalData() {
            this.totalData = {};
            this.$axios
                .post('/interfaceApi/procurement/accounts/balance_calculate_total', {
                    balance_number: this.gbdbh, // 过磅单编号
                    // task_number: this.gydbh, // 供应单编号
                    type_code: this.yclfl, // 原材料分类编码
                    child_type_code: this.yclzlbm, // 原材料子类编码
                    spec_code: this.yclggbm, // 原材料规格编码
                    supplier_name: this.gys, // 供应商
                    station_id: this.czmc, // 场站名称
                    transport_name: this.ysdw, // 运输单位
                    plate_number: this.cph, // 车牌号
                    delivery_time_start: this.ccsjd.length > 1 ? this.ccsjd[0] : '', // 出场时间开始
                    delivery_time_end: this.ccsjd.length > 1 ? this.ccsjd[1] : '', // 出场时间结束
                    print_time_start: this.gbddysjd.length > 1 ? this.gbddysjd[0] : '', // 过磅单打印时间开始
                    print_time_end: this.gbddysjd.length > 1 ? this.gbddysjd[1] : '', // 过磅单打印时间结束
                    balance_status: this.hdzt,
                    pageindex: this.count,
                    pagesize: 100,
                })
                .then(res => {
                    this.totalData = res;
                    this.listLoading = false;
                })
                .catch(error => {
                    this.listLoading = false;
                    this.$message.error(error.ErrorCode.message);
                });
        },
        // 合计行方法
        getSum() {
            const sums = [];
            for (let i = 0; i < 19; i++) {
                sums.push(' ');
            }
            if (Object.keys(this.totalData).length !== 0) {
                sums[10] = this.totalData.deduction_net_weight;
                sums[11] = this.totalData.checked_net_weight;
                sums[17] = this.totalData.money;
                sums[18] = this.totalData.adjusted_money;
                sums[19] = this.totalData.transport_money;
            }
            return sums;
        },
        // 清除滑动
        clearHover(event) {
            event.preventDefault();
            event.stopPropagation();
            this.clearAlertShow();
        },
        // 清除点击方法
        clearAlertShow() {
            this.tableData.forEach(item => {
                item.show = false;
            });
        },
        // 唤醒弹窗
        awakenAlert(key) {
            this.clearAlertShow();
            let foemUrl = 'FormCode=cg_accounts_gbd_hs';
            if (key.balance_status === 1) {
                foemUrl = 'FormCode=cg_accounts_gbd_hs&IsView=true';
            }
            this.$HsAndHdLayer(foemUrl, key.id, '核算', 'gbd');
        },
        // 上拉加载
        getMoreLog() {
            const listLength = Number(this.tableData.length) - 1;
            if (listLength < this.listLength) {
                this.listLoading = true;
                this.getHeSuanList('pullUp');
            } else {
                this.allData = true;
            }
        },
        // 列表多选
        handleSelectionChange(value) {
            this.selectList = value;
        },
        // 批量发送
        bulkSend() {
            if (this.selectList.length === 0) {
                this.$message.error('请选选择过磅单');
                return;
            }
            const idList = [];
            const balance_ids = [];
            this.selectList.forEach(item => {
                if (item.balance_number) {
                    idList.push(item.balance_number);
                }
                if (item.balance_id) {
                    balance_ids.push(item.balance_id);
                }
            });
            const obj = {
                balance_numbers: idList,
                balance_ids: balance_ids,
                status: 4,
            };
            this.$axios
                .put('/interfaceApi/procurement/accounts/balance_batch_check', obj)
                .then(() => {
                    this.listLoading = false;
                    this.$message.success('发送成功');
                    this.getHeSuanList('queryList');
                })
                .catch(error => {
                    this.listLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 批量调价
        batchAdjustPrice() {
            if (this.selectList.length === 0) {
                this.$message.error('请选选择过磅单');
                return;
            }
            const contract_no = this.selectList[0].contract_no;
            const spec_code = this.selectList[0].spec_code;
            const spec_dic_code = this.selectList[0].spec_dic_code;
            let warnMsg = '';
            const filterSpecCodes = ['203', '204'];
            const isIncludeCode = this.selectList.filter(item => filterSpecCodes.includes(item.spec_code)).length > 0;
            for (const item of this.selectList) {
                if (item.contract_no !== contract_no) {
                    warnMsg = '不是同一种合同，不允许调价'; break;
                }
                if (isIncludeCode) {
                    if (item.spec_code !== spec_code) {
                        warnMsg = '不是同一种原材料规格，不允许调价'; break;
                    }
                } else if (item.spec_dic_code !== spec_dic_code) {
                    warnMsg = '不是同一种原材料规格，不允许调价'; break;
                }
            }
            if (warnMsg) {
                this.$message.warning(warnMsg);
                return;
            }
            const balance_numbers = this.selectList.map(item => item.balance_number);
            this.$toast(
                {
                    title: true,
                    text: '批量调价',
                    type: 'eject',
                    width: '5rem',
                    height: '4rem',
                    t_url: 'accountingManagement/batchAdjustPrice',
                    viewPosition: 'view',
                    extr: {
                        balance_numbers: balance_numbers,
                        callback: () => this.getHeSuanList('queryList'),
                    },
                });
        },
        // 获取核算列表
        getHeSuanList(status) {
            this.listLoading = true;
            if (status === 'pullUp') {
                this.count++;
            } else if (status === 'queryList') {
                this.count = 1;
            }
            this.$axios
                .post('/interfaceApi/procurement/accounts/balance_calculate_page', {
                    balance_number: this.gbdbh, // 过磅单编号
                    // task_number: this.gydbh, // 供应单编号
                    type_code: this.yclfl, // 原材料分类编码
                    child_type_code: this.yclzlbm, // 原材料子类编码
                    spec_code: this.yclggbm, // 原材料规格编码
                    supplier_name: this.gys, // 供应商
                    station_id: this.czmc, // 场站名称
                    transport_name: this.ysdw, // 运输单位
                    plate_number: this.cph, // 车牌号
                    delivery_time_start: this.ccsjd.length > 1 ? this.ccsjd[0] : '', // 出场时间开始
                    delivery_time_end: this.ccsjd.length > 1 ? this.ccsjd[1] : '', // 出场时间结束
                    print_time_start: this.gbddysjd.length > 1 ? this.gbddysjd[0] : '', // 过磅单打印时间开始
                    print_time_end: this.gbddysjd.length > 1 ? this.gbddysjd[1] : '', // 过磅单打印时间结束
                    balance_status: this.hdzt,
                    pageindex: this.count,
                    pagesize: 50,
                })
                .then(res => {
                    this.listLength = res.total;
                    if (status === 'pullUp') {
                        this.tableData.push(...res.rows);
                        this.listLoading = false;
                    } else if (status === 'queryList') { // 查询
                        // 获取合计数据
                        this.getTotalData();
                        this.allData = false;
                        this.tableData = [];
                        this.tableData.push(...res.rows);
                    } else {
                        // 获取合计数据
                        this.getTotalData();
                        this.tableData = [];
                        this.tableData.push(...res.rows);
                    }
                    // 列表当前加载页
                    this.tableCurrentPage = this.tableData.length;
                })
                .catch(error => {
                    this.listLoading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        aaa() {
            this.tableData = [];
            this.getHeSuanList('queryList');
        },
        updateTable() {
            this.aaa();
        },
        // 导出excel
        exportExcel(url, data = {}) {
            return new Promise(() => {
                this.$axios.post('/interfaceApi/procurement/accounts/export_balance_calculate', {
                    balance_number: this.gbdbh, // 过磅单编号
                    // task_number: this.gydbh, // 供应单编号
                    type_code: this.yclfl, // 原材料分类编码
                    child_type_code: this.yclzlbm, // 原材料子类编码
                    spec_code: this.yclggbm, // 原材料规格编码
                    supplier_name: this.gys, // 供应商
                    station_id: this.czmc, // 场站名称
                    transport_name: this.ysdw, // 运输单位
                    plate_number: this.cph, // 车牌号
                    delivery_time_start: this.ccsjd.length > 1 ? this.ccsjd[0] : '', // 出场时间开始
                    delivery_time_end: this.ccsjd.length > 1 ? this.ccsjd[1] : '', // 出场时间结束
                    print_time_start: this.gbddysjd.length > 1 ? this.gbddysjd[0] : '', // 过磅单打印时间开始
                    print_time_end: this.gbddysjd.length > 1 ? this.gbddysjd[1] : '', // 过磅单打印时间结束
                    balance_status: this.hdzt,
                }, { responseType: 'arraybuffer' })
                    .then(res => {
                        const blob = new Blob([res.data], { type: 'application/octet-stream' });
                        const fileName = `过磅单核算${this.timeFormat(new Date())}.xls`;
                        if ('download' in document.createElement('a')) { // 非IE下载
                            const elink = document.createElement('a');
                            elink.download = fileName;
                            elink.style.display = 'none';
                            elink.href = URL.createObjectURL(blob);
                            document.body.appendChild(elink);
                            elink.click();
                            URL.revokeObjectURL(elink.href); // 释放URL 对象
                            document.body.removeChild(elink);
                        } else { // IE10+下载
                            navigator.msSaveBlob(blob, fileName);
                        }
                    }).catch(() => {
                        if (data.Vue) {
                            this.$message.error('系统异常');
                        }
                    });
            });
        },
        // 格式化时间
        timeFormat(date) {
            const y = date.getFullYear(); // 年
            let m = date.getMonth() + 1; // 月
            let d = date.getDate(); // 日
            let hour = date.getHours(); // 时
            let minutes = date.getMinutes(); // 分
            let s = date.getSeconds(); // 秒
            m = m > 9 ? m : '0' + m;
            d = d > 9 ? d : '0' + d;
            hour = hour > 9 ? hour : '0' + hour;
            minutes = minutes > 9 ? minutes : '0' + minutes;
            s = s > 9 ? s : '0' + s;
            return `${y}-${m}-${d} ${hour}:${minutes}:${s}`;
        },

        // 退回
        handleBack(row) {
            if (row.account_statement_status !== 1) {
                this.$confirm('是否退回该过磅单?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$axios
                        .put('/interfaceApi/procurement/accounts/return_balance?id=' + row.id)
                        .then(() => {
                            this.$message.success('已退回！');
                            this.updateTable();
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                            this.updateTable();
                        });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消',
                    });
                });
            }
        },

    },
    beforeDestroy() {
        // 删除记录打开列表
        this.$store.commit('deleteRecordOpenList', this.tableRefNmame);
    },
};

</script>
<style lang='stylus'>
.weighingListBusiness
    .business-accounting-box
        background #fff
        width 100%
        padding .15rem
        height 100%
        display flex
        flex-direction column
        overflow-y auto
        .query-criteria
            display flex
            align-items center
            flex-wrap wrap
            border-bottom 1px solid #E7E7E7
            flex 1
            >div
                display flex
                align-items center
                margin-bottom .05rem
                p
                    min-width 1rem
                    font-size .16rem
                    line-height 36px
                    vertical-align top
                    text-align right
                    color #8593A7
                    padding-right 0.1rem
                .el-input
                    width: 2rem;
                    margin-right: .2rem;
                    input
                        padding-left .1rem
                .el-date-editor
                    margin-right .2rem
                .el-button
                    background #2978ff
                    color #fff
                    width .9rem
                    height .35rem
                    display flex
                    align-items: center;
                    justify-content: center;
                    border-color #2978ff
                    span
                        line-height 0
        .bulk-send
            padding 0
            width .9rem
            height .35rem
            border-color #2978ff
            background #2978ff
            color #fff
            font-size .16rem
            margin .1rem
            flex .3
        .table-list
            height calc(100% - 2.2rem)
            .yunshuNumber
                cursor:pointer;
                color blue
                text-decoration underline
            .el-table__body-wrapper
                height calc(100% - 0.8rem) !important
            .el-table__footer-wrapper
                height .4rem
                th
                    border-color #EBEEF5
                td
                    background #FEF9E4
                    border none
                    cursor pointer
                    div
                        color #FF6600 !important
                        font-weight 400
            .el-table
                border: 1px solid #EBEEF5;
                &:before,
                &:after
                    display none
                tr
                    &.current-row
                        >td
                            background #FFF2DD !important
                            border-top 1px solid #E8DCC9
                            border-bottom 1px solid #E8DCC9
                            &:first-child
                                border-left 1px solid #E8DCC9
                            &:last-child
                                border-right 1px solid #E8DCC9
                td,th
                    padding 0
                    height .4rem !important
                th
                    border-color rgba(215,215,215,0.7)
                    &.gutter
                        display block !important
                        width 10px !important
            .el-table tbody tr td
                height .4rem
                text-align center
                .cell
                    font-size .14rem !important
                    color #333333
                    font-weight 400
                    cursor pointer
                    span
                        font-size .14rem !important
                        color blue
        .fonter
            height 0.4rem
            font-size 0.16rem
            line-height 0.5rem
            display flex
            justify-content flex-end
            span
                font-size 0.2rem
                padding 0 0.05rem
                &:nth-of-type(1)
                    color #28e14b
                &:nth-of-type(2)
                    color #d40004
            .multipleSelected
                color #979ea7
                font-size 0.13rem
                margin-right 0.2rem
                span
                    color #d40004
                    font-size 0.18rem
                    padding 0 0.1rem
.business-accounting-popover
    width 12.6rem !important
    .click-look-details-check
        display flex
        flex-wrap wrap
        div
            display flex
            width 4rem
            height .4rem
            border 1px solid #E7E7E7
            margin 0 .05rem .05rem 0
            p:first-child
                width 1.7rem
                background #F2F6FC
                color #022782
                display flex
                align-items center
                padding 0 .2rem
                font-weight 400
                font-size: .14rem;
            p:last-child
                width 2.3rem
                font-size .14rem
                padding .2rem
                overflow: hidden;
                white-space: nowrap;
                text-overflow:ellipsis
                margin-top: -0.12rem;

</style>
